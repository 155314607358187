
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogisticsAudit from "@/request/apis/api_logistics_audit";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {IDriveLicenceItem} from "@/models/logistics_model";

interface IState {
  list: IDriveLicenceItem[],
  total: number;
  loading: boolean;
  searches: any
  reason: string
  visible: boolean,
  item?: IDriveLicenceItem
  show: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '手机号',
    dataIndex: 'userable',
    key: 'userable',
  },
  {
    title: '驾驶证信息',
    dataIndex: 'license_number',
    key: 'info',
  },
  {
    title: '驾驶员从业资格证号', // 驾驶员从业资格证号
    dataIndex: 'qualification_certificate',
    key: 'qualification_certificate',
  },
  {
    title: '提交时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '驾驶证图片',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '审核状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '审核信息',
    dataIndex: 'audit',
    key: 'audit',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
  },
];

export default defineComponent({
  name: 'DrivingLicenceList',
  components: {EditOutlined, CheckOutlined, CloseOutlined},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      reason: '',// 审核不通过的原因
      visible: false,
      item: undefined,
      show: false,
      searches: {
        page_no: PageUtil.pageNo,
        page_size: PageUtil.pageSize,
        type: '',
        name: '',
        license_number: ''
      }
    })

    const statusText = [{name: '审核中', color: 'orange'}, {name: '审核通过', color: 'green'}, {name: '审核未通过', color: 'red'}]


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize
      state.searches.page_no = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      if (!params.type) {
        delete params.type
      }
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogisticsAudit.getDriverLicenceList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 设置modal显示隐藏
    const setVisible = (item: IDriveLicenceItem, flg: boolean) => {
      state.visible = flg
      state.reason = ''
      if(item){
        state.item = item
      }else{
        setTimeout(() => state.item = undefined, 300)
      }
    }

    // 审核
    const onAudit = async (item: IDriveLicenceItem, flg: boolean) => {
      if (!flg && !state.reason) {
        message.destroy()
        message.warning('请输入不通过的原因')
        return
      }
      state.loading = true
      const res = await ApiLogisticsAudit.auditDriverLicence({
        "driving_license_id": item.id,
        "reason": flg ? "审核通过" : state.reason,
        "is_passed": flg
      })
      state.loading = false
      setVisible(item, false)
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        message.error(res?.msg || '审核失败');
      }
    }

    const setShow = (item: IDriveLicenceItem, flg: boolean) => {
      state.show = flg
      if(item){
        state.item = item
      }else{
        setTimeout(() => state.item = undefined, 300)
      }
    }

    // 详情
    const goDetail = (item: IDriveLicenceItem) => {
      router.push({path: '/audit/driving_licence_detail', query: {id: item.id}}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any) => {
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      // state.subCategory = []
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      statusText,
      goDetail,
      handleTableChange,
      onSearch,
      resetSearch,
      onAudit,
      setVisible,
      setShow
    }
  }
})
